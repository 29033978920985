<template>
  <div id="containerpapa">
    <h2>Order Summary</h2>
   <div id="orders">
      <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   <div class="order">
      <div class="image">
        <img src="https://static.nike.com/a/images/t_PDP_864_v1/f_auto,b_rgb:f5f5f5/f13f4c5d-9039-43f4-8e5f-8b7e4bafb259/jordan-why-not-zer0-5-older-basketball-shoes-6x0vk2.png" alt="Prod Image">
      
    </div>
    <div class="details">
        <h4>Title of the product that you are purchasing</h4>
        <h5>QTY:1</h5>
      </div>
      <div class="amount">
        <h4>₹6969</h4>
      </div>
   </div>
   
  </div>
   <div class="divider1"></div>
    <div class="bill">
      <div class="billDetails">
         <div>
           <h3 class="strong">Sub Total</h3>
         </div>
         <div>
           <h3 class="amount">₹10000</h3>
         </div>
      </div>
      <div class="billDetails">
        <div>
           <h3 class="strong">Promo Code</h3>
         </div>
         <div>
           <h3 class="amount" style="color:rgba(0,0,0,0.5)">-₹1000</h3>
         </div>
         
      </div>
      <div class="billDetails">
        <div>
           <h3 class="strong">Delivery Charge</h3>
         </div>
         <div>
           <h3 class="amount" style="color:rgba(0,0,0,0.5)">Free</h3>
         </div>
        
      </div>
      <div class="divider2"></div>
      <div class="billDetails">
        <div>
           <h3 class="strong" style="font-size:24px">Total</h3>
         </div>
         <div>
           <h3 class="amount" style="font-size:24px">₹9000</h3>
         </div>
         
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style scoped>
#containerpapa{
  width:400px;
  height:fit-content;
  padding-left:.25%;
  padding-right:.25%;
  padding-top:.75%;
  padding-bottom:4.75%;
  border-radius: 6px;
  border:1px solid rgb(179, 178, 178);
}
h2{
  text-align: center;
  font-weight: 600;
  font-size:18px;
  font-style:normal;
}
#orders{
  width:380px;
  height:fit-content;
  max-height:300px;
  overflow: auto;
  margin-top:10%;
}
#orders::-webkit-scrollbar{
  display: none;
}
.order{
  width:400px;
  height:130px;
  margin-top:2.0%;
  margin-bottom:2.50%;
  box-shadow: 2px 2px 2px rgb(238, 237, 237);
  border-radius: 7px;
  display: grid;
  grid-template-columns: 110px 120px 100px;
  padding-left: 2.25%;
}
.image img{
  width:90px;
  height:120px;
}
.details{
  padding-top:10px;
}
.details h4{
  font-weight: 500;
  font-style: normal;
  font-size:16px;
  line-height: 1.15;
}
.details h5{
  margin-top:14px;
  font-weight: 500;
  font-style: normal;
  font-size:16px;
  color:rgb(110, 109, 109)
}
.amount h4{
margin-top:20px;
margin-left:65%;
  font-weight: 500;
  font-style: normal;
  font-size:20px;
  line-height: 1.15;
}
.divider1{
  width:100%;
  height:1px;
  background-color:rgb(26, 25, 25);
  margin-top:4%;
  margin-bottom: 1%;
}
.divider2{
  width:100%;
  height:1px;
  background-color:rgb(190, 189, 189);
  margin-top:4%;
  margin-bottom: 1%;
}
.billDetails{
  display: grid;
  grid-template-columns: 180px 180px;
  height:34px;
  padding-left:7%;
}
.amount {
 margin-top:16px;
margin-left:45%;
  font-weight: 500;
  font-style: normal;
  font-size:20px;
  line-height: 1.15;

}
.strong{
   margin-top:20px;
margin-left:.25%;
font-weight: 400;
  font-style: normal;
  font-size:18px;
  line-height: 1.15;
}
</style>