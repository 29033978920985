<template>
  <div id="container">
<h2 class="title">Delivery Address</h2>
<div class="divider"></div>
   <div id="baap">
    <div id="form">
      <div class="input">
        <h3>Address Line 1</h3>
        <v-text-field 
            outlined
            full-width
            v-model="address_line1"
            ></v-text-field>    
      </div>
      <div class="input">
        <h3>Address Line 2</h3>
        <v-text-field 
            outlined
            full-width
            v-model="address_line2"
            ></v-text-field>    
      </div>
      <div class="input">
        <h3>Landmark</h3>
        <v-text-field 
            outlined
            full-width
            v-model="landmark"
            ></v-text-field>    
      </div>
      <div class="input" style="width:315px">
        <h3>Postal Code</h3>
        <v-text-field 
            outlined
            v-model="postalCode"
            ></v-text-field>    
      </div>
      <div class="input" style="width:315px">
        <h3>State</h3>
        <v-app>
          <v-combobox
          v-model="state"
          :items="states"
          label="State"
          outlined>
          </v-combobox>
        </v-app>
      </div>
      <div class="input" style="width:315px">
        <h3>City</h3>
         <v-app>
          <v-combobox
          v-model="city"
          label="City"
          outlined>
          </v-combobox>
        </v-app>
      </div>
      <div class="input" style="width:315px">
        <h3>Area</h3>
         <v-app>
          <v-combobox
          v-model="area"
         
          label="Area"
          outlined>
          </v-combobox>
        </v-app>
      </div>
      <v-btn dark x-large>Continue</v-btn>
     </div>
     <div id="summary">
       <order-summary></order-summary>
     </div>
   </div>
  </div>
</template>

<script>
//add form validation logic before moving to next page

import TheSummary from './order-details.vue'
export default {
  components:{
    'order-summary':TheSummary,
  },
  data(){
    return{
      postalCode:"",
      state:"",
      city:"",
      address_line1:"",
      address_line2:"",
      landmark:"",
      area:"",
      //this is the format 
      //country[states[cities]]
      //using [state[city]]


      states:["Andhra Pradesh",
"Arunachal Pradesh",
"Assam",
"Bihar",
"Chhattisgarh",
"Goa",
"Gujarat",
"Haryana",
"Himachal Pradesh",
"Jharkhand",
"Karnataka",
"Kerala",
"Madhya Pradesh",
"Maharashtra",
"Manipur",
"Meghalaya",
"Mizoram",
"Nagaland",
"Odisha",
"Punjab",
"Rajasthan",
"Sikkim",
"Tamil Nadu",
"Telangana",
"Tripura",
"Uttarakhand",
"Uttar Pradesh",
"West Bengal"],
      //add this to the database for easy access
      location:[
        {"Arunachal Pradesh":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Assam":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Bihar":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Chhattisgarh":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Goa":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Gujarat":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Haryana":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Himachal Pradesh":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Jharkhand":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Karnataka":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Kerala":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Madhya Pradesh":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Maharashtra":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Manipur":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Meghalaya":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Mizoram":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Nagaland":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Odisha":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Punjab":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Rajasthan":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Sikkim":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Tamil Nadu":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Telangana":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Tripura":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Uttarakhand":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"Uttar Pradesh":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        {"West Bengal":[{"city1":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city2":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city3":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city4":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city5":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city6":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city7":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city8":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city9":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city10":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city11":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city12":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city13":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city14":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city15":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city16":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city17":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city18":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city19":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city20":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city21":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city22":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city23":["area1","area2","area3","area4","area5","area6","area7","area8",]},
        {"city24":["area1","area2","area3","area4","area5","area6","area7","area8",]},]},
        


      ]
    }
  },
  methods:{
  validateForm(){
// add form validation logic in here
this.$store.commit('/usersetCheckoutState',{value:1});
this.$store.commit('/usersetCheckoutState',{value:2});

  },
  }

}
</script>

<style scoped>
#container{
 padding-top:.75%; 
}
#baap{
  margin-top:20px;
  width:700px;
  padding-top:0.75%;
  display:grid;
  grid-template-columns: auto auto;
  padding-left:20%;
}
.title{
  font-weight:600;
  font-style:normal;
  font-size:20px;
  margin-left:20%;
}
.divider{
  width:700px;
  height:1px;
  background-color:rgb(143, 142, 142);
  margin-top:2%;
  margin-bottom:2%;
  margin-left:20%;
}
#form{
  width:700px;
  height:fit-contet;
  padding-top:2%;
  padding-left:1.25%;
  padding-right:1.25%;
  padding-bottom:3%;
}
#summary{
  margin-left:15%;
}
.input{
  width:700px;
}

.input h3{
  font-weight:400;
  font-style:normal;
  font-size:18px;
  margin-bottom:12px;
}
 ::v-deep .v-application--wrap {
    min-height: fit-content;
  }
</style>