<template>
<div>
  <div id="container-offer">
    <h1 class="offers" style="margin-left:5%;">Offers Just for you!</h1>
    <div id="row1" class="row left">
      <div class="imageLeft ">
        <img :src="require('/src/assets/offers/offer1.png')" alt="Offer 1">
      </div>
      <div class="detailsLeft">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      </div>
    <div id="row2" class="row right">
       <div class="detailsRight">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      <div class="imageRight">
        <img :src="require('/src/assets/offers/offer2.png')" alt="Offer 2">
      </div>
     
    </div>
    <div id="row3" class="row left">
      <div class="imageLeft">
        <img :src="require('/src/assets/offers/offer3.png')" alt="Offer 3">
      </div>
      <div class="detailsLeft">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
    </div>
    <div id="row4" class="row right">
      <div class="detailsRight">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      <div class="imageRight">
        <img :src="require('/src/assets/offers/offer10.png')" alt="Offer4">
      </div>
    </div>
    <div id="row5" class="row left">
      <div class="imageLeft">
        <img :src="require('/src/assets/offers/offer4.png')" alt="Offer 5">
      </div>
      <div class="detailsLeft">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
    </div>
    <div id="row6" class="row right">
      <div class="detailsRight">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      <div class="imageRight">
        <img :src="require('/src/assets/offers/offer7.png')" alt="Offer 6">
      </div>
    </div>
    <div id="row7" class="row left">
      <div class="imageLeft">
        <img :src="require('/src/assets/offers/offer5.png')" alt="Offer 7">
      </div>
      <div class="detailsLeft">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
    </div>
    <div id="row8" class="row right">
      <div class="detailsRight">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      <div class="imageRight">
        <img :src="require('/src/assets/offers/offer6.png')" alt="Offer 8">
      </div>
    </div>
    <div id="row9" class="row left">
      <div class="imageLeft">
        <img :src="require('/src/assets/offers/offer9.png')" alt="Offer 9">
      </div>
      <div class="detailsLeft">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
    </div>
    <div id="row10" class="row right">
      <div class="detailsRight">
        <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
      </div>
      <div class="imageRight">
        <img :src="require('/src/assets/offers/offer8.png')" alt="Offer 10">
      </div>
    </div>
    </div>  
  <div id="mob">
    <h1 class="offers" style="margin-left:5%;">Offers Just for you!</h1>
    <div class="row">
      <img :src="require('/src/assets/offers/offer1.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer2.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer3.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer4.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer5.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer6.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer7.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer8.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer9.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
    <div class="row">
      <img :src="require('/src/assets/offers/offer10.png')" alt="offer">
      <h1>This will be the heading</h1>
        <h3>Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.Add a detailed description abput the offer in this section.</h3>
    </div>
  </div>
</div>
</template>

<script>
export default {
    mounted(){
     window.scrollTo(0,0);
  }
}
</script>

<style>
@media only screen and (min-width:900px){
  #container-offer{
    padding-top:2%;
    padding-left:1.5%;
    padding-right:1.5%;
    padding-bottom:2%;
  }
  #row1{
    display:grid;
    grid-template-columns: repeat(2,auto);
  }
  .row{
    overflow-x:auto;
    margin-top:40px;
    margin-bottom:40px;
  }
  .row::-webkit-scrollbar{
    display:none;
  }
  .imageLeft{
    cursor: pointer;
    margin-left:3%;
  }
  .imageRight
  {
    cursor: pointer;
  margin-left:2%;
  }
  .left{
    
    display: grid;
    grid-template-columns: auto auto;
    }
  .right{
    
    display: grid;
    grid-template-columns: auto auto;
  }
  .detailsLeft{
    margin-left:2.5%;
    padding-right:2.5%;
    padding-left:2.5%;
  }
  .detailsLeft h1{
    font-weight:500;
    font-style:normal;
   font-size:28px;
   text-align:center;
  margin-bottom:1.5%;
  }
  .detailsLeft h3{
     font-weight:300;
    font-style:normal;
   font-size:16px;
  margin-top:1.5%;
  padding-left:2%;
  padding-right:2%;
  line-height:1.75;
  }
  .detailsRight{
    padding-right:2.5%;
    padding-left:2.5%;
  }
  .detailsRight h1{
    font-weight:500;
    font-style:normal;
   font-size:28px;
   text-align:center;
  margin-bottom:1.5%;
  }
  .detailsRight h3{
     font-weight:300;
    font-style:normal;
   font-size:16px;
  margin-top:1.5%;
  padding-left:2%;
  padding-right:2%;
  line-height:1.75;
  }
  .offers{
  text-align: center;
  font-weight: 500;
  margin-top:10px;
  margin-bottom:20px
  }
  #mob{
    display: none;
  }
}
@media only screen and (max-width:480px){
  #container-offer{
    display: none;
  }
  .offers{
    text-align: center;
    font-weight: 500;
    margin-top:10px;
    margin-bottom:20px
    }
    .row{
      width:100vw;
    }
    .row h1{
      text-align: center;
      font-size:24px;
      font-weight: 550;
    }
    .row h3{
      width:100vw;
      font-size:20px;
      font-weight:500;
      margin-left:5vw;
    }
    .row img{
      width:98vw;
      height:350px;
      margin-left:4.5vw;
    }
    .row{
      margin-top:10px;
      margin-bottom:10px;
    }

}
</style>